import React from 'react';

import Container from 'components/ui/Container';

import * as Styled from './styles';

const Footer = () => (
  <Styled.Footer>
    <Container>
      <Styled.Links>
        <Styled.Link href="https://github.com/vikpande" rel="noreferrer noopener" target="_blank">
          GitHub
        </Styled.Link>
        <Styled.Link
          href="https://www.crunchbase.com/person/vikas-pandey"
          rel="noreferrer noopener"
          target="_blank">
          Crunchbase
        </Styled.Link>
        <Styled.Link href="https://www.linkedin.com/in/vikpande/" rel="noreferrer noopener" target="_blank">
          LinkedIn
        </Styled.Link>
        <Styled.Link href="https://drive.google.com/file/d/17OpSAIpaIyZsqOKEALSfRtoOyoaltph3/view?usp=sharing" rel="noreferrer noopener" target="_blank">
          Résumé
        </Styled.Link>
        <Styled.Link href="https://drimble.nl/bedrijf/amsterdam/46357092/byte-holding-bv.html" rel="noreferrer noopener" target="_blank">
          Byte|AMS
        </Styled.Link>
      </Styled.Links>
    </Container>
  </Styled.Footer>
);

export default Footer;
